export const TERMS_MEU_ACERTO = 'https://meuacerto.com.br/politica-de-privacidade'
export const POLICY_INTER = 'https://www.bancointer.com.br/politica-de-privacidade/'
export const DEEPLINK_NEGOCIACAO_AMIGAVEL =
  'bancointer://meiosPagamento/rota/NEGOCIACAO_AMIGAVEL_ONBOARDING'

export const BASE_URL = process.env.REACT_APP_BASE_URL

export const MEU_ACERTO_LOGIN = '/app/meios-pagamentos/meu-acerto/v1/login/homeapp'
export const EXAMPLE_ENDPOINT = ''
export const ELIGIBLE_API = '/app/meios-pagamentos/meu-acerto/v1/pre-creli-eligible'
