import InterWebviewBridge, {
  interWbSession,
  interWbHttp,
  interWbNavigate,
  IWbHttpOptions,
  IWbHttpData,
  IWbUserInfo,
  interWbAuth,
  WbEnvironments,
} from '@interco/inter-webview-bridge'

import { DeepLinksPF, DeepLinksPJ, DeepLinks } from './deeplinks'
import * as Types from './types'
import * as LoggerService from '../logger'

export { DeepLinks, DeepLinksPJ, DeepLinksPF }
export { interWbNavigate }
export const iWb = InterWebviewBridge.getInstance()
export const environment = iWb.getEnvironment()
export const inApp = environment !== WbEnvironments.BROWSER

export const getAppInfo = (): Promise<Types.AppInfo> =>
  inApp
    ? interWbSession.getAppInfo()
    : Promise.resolve({
        version: 'version',
        theme: 'inter',
        tema: 'inter',
        deviceName: 'deviceName',
        deviceType: 'deviceType',
        nomeAparelho: 'nomeAparelho',
        versao: 'versao',
        nomeTipo: 'nomeAparelho',
      })

export const getUserInfo = (): Promise<IWbUserInfo> =>
  inApp
    ? interWbSession.getUserInfo()
    : Promise.resolve({
        name: 'name',
        email: 'email',
        cpf: 'cpf',
        account: 'account',
        phoneNumber: 'phoneNumber',
        userType: 'basic_account',
        profile: '',
        segmentation: 'BASIC',
        addressComplement: 'addressComplement',
        addressName: 'addressName',
        addressNumber: 'addressNumber',
        birthDate: 123456,
        cellPhoneNumber: 'cellPhoneNumber',
        cep: 'cep',
        city: 'city',
        monthlyIncome: 0,
        neighborhood: 'neighborhood',
        state: 'state',
      })

export async function request<T = Record<string, unknown>>({
  method,
  endpoint,
  userAccount,
  ...args
}: Types.Request & { userAccount: string }): Promise<T | string> {
  if (!inApp) return Promise.reject(new Error(''))

  let response: IWbHttpData
  const headers = args.headers || {}
  const options: IWbHttpOptions = { timeout: 30_000, ...args.options }

  switch (method) {
    default:
    case 'GET':
    case 'DELETE':
      response = await interWbHttp[method.toLowerCase() as 'get' | 'delete'](
        endpoint,
        headers,
        options,
      )
      break

    case 'POST':
    case 'PUT':
      response = await interWbHttp[method.toLowerCase() as 'post' | 'put'](
        endpoint,
        (args as Types.PostOrPut).body,
        headers,
        options,
      )
      break
  }

  const data = response

  LoggerService.log(`[${method}] ${endpoint}`, {
    response,
    responseDataJSON: JSON.parse(data.response),
    headers,
    options,
    body: (args as Types.PostOrPut).body || undefined,
  })

  if (data.httpStatus === 200 || data.httpStatus === 201) {
    try {
      return JSON.parse(data.response)
    } catch (error) {
      LoggerService.error(
        {
          screenOrModule: 'BridgeService',
          reason: (error as Error).message,
          userAccount,
        },
        { error },
      )

      return data.response
    }
  }

  return data.response
}

export const navigateToNativeScreen = (screen: Types.DeepLinks): Promise<unknown> => {
  if (inApp) {
    return interWbNavigate.openDeepLink(screen)
  }
  return Promise.resolve(undefined)
}

export const requestAnalytics = async (name: string, params?: Record<string, string>) => {
  if (inApp) {
    try {
      await interWbSession.requestAnalytics(name, params)
    } catch (error) {
      window.console.error(error)
    }
  }
}

export const getSuperAppType = async (): Promise<Types.SuperAppType | undefined> => {
  if (inApp) {
    return iWb.execute<Types.SuperAppType, undefined>({
      action: Types.Actions.GET_SUPER_APP_TYPE,
    })
  }

  return Promise.resolve(undefined)
}

export const requestGoBack = async (userAccount: string): Promise<unknown> => {
  if (inApp) {
    try {
      await interWbNavigate.requestGoBack()
      return Promise.resolve()
    } catch (error) {
      LoggerService.error({
        screenOrModule: 'BridgeService => requestGoBack',
        userAccount,
        reason: (error as Error).message,
      })
    }
  }

  return Promise.resolve(undefined)
}

export const navigateHome = async (): Promise<void> => {
  if (inApp) {
    if (iWb.getEnvironment() === WbEnvironments.ANDROID) {
      return iWb.execute<void, undefined>({
        action: Types.Actions.NAVIGATE_TO_HOME,
      })
    }
    if (iWb.getEnvironment() === WbEnvironments.IOS) {
      return iWb.execute<void, undefined>({
        action: Types.Actions.NAVIGATE_HOME,
      })
    }
  }
  return Promise.resolve(undefined)
}

export const openNativeBrowser = (url: string): Promise<string | void> => {
  if (inApp) {
    return iWb.execute<string, unknown>({
      action: Types.Actions.OPEN_NATIVE_BROWSER,
      metadata: { url },
    })
  }

  return Promise.resolve(undefined)
}

export const authenticateRequestCashback = (): Promise<ISafe | undefined> => {
  if (inApp) {
    const CATEGORY = ''
    const CHECK_TYPE = ''
    const FEATURE = ''
    const VALUE = 0

    return interWbAuth.requestISafe(CATEGORY, CHECK_TYPE, FEATURE, VALUE)
  }

  return Promise.resolve(undefined)
}
