import React, { useEffect, useCallback, useState, useMemo } from 'react'

import InterWebviewBridge, { interWbNavigate, interWbSession } from '@interco/inter-webview-bridge'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { ReactComponent as Hero } from '@/assets/images/redirect.svg'
import { getURLRedirectMeuAcerto } from '@/services/meu-acerto'

import * as S from './styles'

const iWb = InterWebviewBridge.getInstance()

const RedirectView: React.FC = () => {
  const [showGoBack, setShowGoBack] = useState(false)
  const isMobile = iWb.getEnvironment() !== 'BROWSER'

  const goBackHome = () => {
    try {
      interWbNavigate.requestGoBack()
    } catch (error) {
      global.console.log(error)
    }
  }

  const handleGetUrlRedirect = useCallback(async () => {
    try {
      const webview = await getURLRedirectMeuAcerto()
      if (webview?.url && isMobile) {
        interWbSession.editToolbar('Negociação de dívidas', true)
        window.location.href = webview.url
      } else {
        setShowGoBack(true)
      }
    } catch (error) {
      setShowGoBack(true)
    }
  }, [setShowGoBack, isMobile])

  useEffect(() => {
    handleGetUrlRedirect()
  }, [handleGetUrlRedirect])

  const View = useMemo(() => {
    if (showGoBack) {
      return (
        <>
          <Text variant="headline-h1" color="typography" colorWeight={500} semiBold>
            Ops!
          </Text>
          <Text variant="body-3" color="typography" colorWeight={400}>
            Esse serviço está temporariamente fora do ar, tente mais tarde.
          </Text>
          <Button onClick={goBackHome} type="button" style={{ margin: '24px 0 0' }}>
            Voltar
          </Button>
        </>
      )
    }
    return (
      <>
        <Text variant="headline-h1" color="typography" colorWeight={500} semiBold>
          Estamos te direcionando para a nossa parceira Acerto!
        </Text>
        <Text variant="body-3" color="typography" colorWeight={400}>
          É bem rápido! Por lá, você poderá criar uma proposta de negociação que caiba no seu bolso.
          Isso tudo sem sair do app do Inter e com retorno imediato.
        </Text>
      </>
    )
  }, [showGoBack])

  return (
    <S.Container>
      <Hero />
      {View}
    </S.Container>
  )
}

export default RedirectView
