import { createReducer } from 'cdpro-redux-utils'

import { RootState } from './types'
import { setWelcomeMessage } from './actions'

export const INITIAL_STATE: RootState = {
  message: 'Ready to start developing!',
}

const { reducer, addCase } = createReducer<RootState>(INITIAL_STATE)

addCase(setWelcomeMessage, (draft, action) => {
  const { message } = action.payload

  draft.message = message
})

export default reducer
