import React from 'react'

import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { BottomSheet } from '@interco/inter-ui/components/BottomSheet'

import * as S from './styles'

const Help: React.FC<{
  isVisible: boolean
  setIsVisible: (status: boolean) => void
}> = ({ isVisible, setIsVisible }) =>
  isVisible ? (
    <BottomSheet onClose={() => setIsVisible(false)}>
      <S.Container>
        <Text variant="headline-h3" color="typography" colorWeight={500} semiBold>
          Negociação de dívidas
        </Text>
        <Text variant="body-3" color="typography" colorWeight={400}>
          <strong>É rápido, simples e seguro!</strong> Aqui, você tem condições especiais para
          negociar as suas dívidas em apenas alguns cliques. Você consegue consultar, criar uma
          proposta personalizada e garantir seu acordo em minutos, de forma totalmente digital, sem
          sair do app do Inter. Abriremos, aqui mesmo, a página da nossa parceira Acerto.
        </Text>
        <Button onClick={() => setIsVisible(false)}>Entendi</Button>
      </S.Container>
    </BottomSheet>
  ) : (
    <></>
  )

export default Help
