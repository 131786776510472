import React, { useState, useMemo, useEffect, useCallback } from 'react'

import InterWebviewBridge, { interWbSession } from 'inter-webview-bridge'

import { Help, RedirectView, Header, Terms } from '@/components'
import { Text } from '@interco/inter-ui/components/Text'
import coverImage from '@/assets/images/hero.png'
import CheckSvg from '@interco/icons/orangeds/SM/check'
import BrowserEnvironmentView from '@/components/ BrowserEnvironmentView'
import { getIsEligible } from '@/services/meu-acerto'
import { HomeShimmer } from '@/components/Loading'

import { pageData } from './data'
import * as S from './styles'

const iWb = InterWebviewBridge.getInstance()

const Main: React.FC = () => {
  const { title, description, info, questions } = pageData
  const [isOpenHelp, setIsOpenHelp] = useState(false)
  const [canRedirect, setCanRedirect] = useState(false)
  const [loadingEligible, setLoadingEligible] = useState(true)
  const isMobile = iWb.getEnvironment() !== 'BROWSER'

  const redirectTo = (url = '/') => {
    window.location.href = url
  }

  const callVerifyIsEligible = useCallback(async () => {
    const { eligible } = await getIsEligible()
    if (eligible) {
      redirectTo(process.env.REACT_APP_BASE_URL_FRIENDLY_NEGOTIATION)
    } else {
      setLoadingEligible(false)
    }
  }, [])

  useEffect(() => {
    callVerifyIsEligible()
  }, [callVerifyIsEligible])

  const MainContainer = useMemo(() => {
    if (isMobile) {
      interWbSession.editToolbar('Negociação de dívidas', false)
    }
    return (
      !canRedirect && (
        <S.Container>
          <Header setIsOpenHelp={setIsOpenHelp} />
          <S.Hero src={coverImage} />
          <S.WrapperIntro>
            <Text variant="headline-h1" colorWeight={500} semiBold>
              {title}
            </Text>
            <Text variant="body-3" color="typography" colorWeight={400}>
              {description}
            </Text>
          </S.WrapperIntro>

          <S.WrapperInfo>
            <Text variant="headline-h3" color="typography" colorWeight={500} semiBold>
              {info.title}
            </Text>

            <S.ListInfo>
              {info.steps.map((step) => (
                <S.ListItem key={step}>
                  <CheckSvg color="var(--gray500)" height={30} width={30} />
                  <Text variant="body-3" color="typography" colorWeight={400}>
                    {step}
                  </Text>
                </S.ListItem>
              ))}
            </S.ListInfo>
          </S.WrapperInfo>

          <S.DividerDashed />

          <S.WrapperQuestions>
            <Text variant="headline-h3" color="typography" colorWeight={500} semiBold>
              {questions.title}
            </Text>

            <S.ListQuestion>
              {questions.steps.map((step) => (
                <S.QuestionInfo key={step.title}>
                  <Text variant="body-3" color="typography" colorWeight={500} bold>
                    {step.title}
                  </Text>

                  <S.QuestionItem>
                    <CheckSvg color="var(--gray500)" height={30} width={30} />

                    <Text variant="body-3" color="typography" colorWeight={400}>
                      {step.description}
                    </Text>
                  </S.QuestionItem>
                </S.QuestionInfo>
              ))}
            </S.ListQuestion>
          </S.WrapperQuestions>
          <Terms setCanRedirect={setCanRedirect} />
          <Help isVisible={isOpenHelp} setIsVisible={setIsOpenHelp} />
        </S.Container>
      )
    )
  }, [
    isMobile,
    canRedirect,
    title,
    description,
    info.title,
    info.steps,
    questions.title,
    questions.steps,
    isOpenHelp,
  ])

  const Redirect = useMemo(() => canRedirect && <RedirectView />, [canRedirect])

  if (loadingEligible) {
    return <HomeShimmer />
  }

  return isMobile ? (
    <>
      {MainContainer}
      {Redirect}
    </>
  ) : (
    <BrowserEnvironmentView />
  )
}

export default Main
