import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 16px;
  span {
    padding: 24px 0;
  }
`

export const WrapperButtons = styled.div`
  display: grid;
  width: 100%;
  margin: 24px auto;
  align-content: center;
  justify-content: center;
  gap: 24px;
  svg {
    height: 48px;
    margin: 0 8px;
  }

  @media (min-width: 768px) {
    display: flex;
  }
`
