import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 0 0 16px 0;
  background-color: var(--neutral-theme);

  button {
    height: 48px;
    max-height: 48px;
    width: calc(100vw - 48px);
  }
`

export const Hero = styled.img`
  width: 100%;
  height: auto;
`

export const WrapperIntro = styled.div`
  display: grid;
  width: 100%;
  gap: 8px;
  padding: 24px 24px 40px;
  border-bottom: 8px solid #f5f6fa;
`

export const WrapperInfo = styled.div`
  display: grid;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 32px 24px 24px;
`

export const ListInfo = styled.div`
  display: grid;
  gap: 16px;
  margin: 24px 0;
`

export const ListItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 20px;
`

export const DividerDashed = styled.div`
  width: 87.5%;
  margin: 0 auto;
  border: 1px dashed #dedfe4;
`

export const WrapperQuestions = styled.div`
  display: grid;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 32px 24px 24px;
  border-bottom: 8px solid #f5f6fa;

  > span {
    margin-bottom: 24px;
  }
`

export const ListQuestion = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const QuestionInfo = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;

  gap: 16px;
`

export const QuestionItem = styled.div`
  display: flex;
  align-items: flex-start;

  gap: 20px;
`
export const WrapLoadingText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40vh;
`

export const Link = styled.span`
  width: auto;
  text-decoration: none;
  color: var(--primary500);
  font-weight: bold;
  outline: none;
  background-color: transparent;
`
