import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  width: 100%;
  gap: 12px;
  padding: 20px 0 24px;
  button {
    margin-top: 20px;
  }
`
