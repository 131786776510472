import styled from 'styled-components'

export const Container = styled.div`
  padding: 18px 0px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: flex-start;
  min-height: 100%;
  background-color: var(--neutral-theme);
  padding-top: 56px;
`

export const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-flow: column wrap;
  padding: 24px 24px;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
`

export const TextContainer = styled.div`
  width: 100%;
  padding: 0 24px;
  display: flex;
  margin-top: 24px;
  flex-flow: column wrap;
  align-items: center;
`
export const TextContainerDonation = styled.div`
  width: 100%;
  padding: 0 24px;
  display: flex;
  margin-top: 24px;
  flex-flow: column wrap;
  align-items: center;
`
export const ContainerSlick = styled.div`
  width: 100%;
  padding: 0 24px;
  display: flex;
  margin-top: 34px;
  flex-flow: column wrap;
  align-items: center;
`
