import React from 'react'

import { interWbNavigate } from '@interco/inter-webview-bridge'
import { ArrowLeft, Help as HelpIcon } from '@/assets/icons'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './styles'

const Header: React.FC<{ setIsOpenHelp: (status: boolean) => void }> = ({ setIsOpenHelp }) => {
  const goBackHome = () => {
    try {
      interWbNavigate.requestGoBack()
    } catch (error) {
      global.console.log(error)
    }
  }

  return (
    <S.Container>
      <ArrowLeft onClick={goBackHome} />
      <Text variant="headline-h3" color="typography" colorWeight={500} semiBold>
        Negociação de dívidas
      </Text>
      <HelpIcon onClick={() => setIsOpenHelp(true)} />
    </S.Container>
  )
}

export default Header
