import React from 'react'

import { StateInspector } from 'reinspect'

import { IS_DEVELOPMENT } from '@/common/constants'

const Inspector = ({ children }: React.PropsWithChildren<unknown>) =>
  IS_DEVELOPMENT ? <StateInspector name="App">{children}</StateInspector> : <>{children}</>

export default Inspector
