import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px 80px;
  overflow: hidden;
  text-align: center;
  span {
    padding-bottom: 8px;
  }

  button {
    height: 48px;
    max-height: 48px;
  }
`
