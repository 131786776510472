import { interWbSession, interWbHttp } from 'inter-webview-bridge'

import { ELIGIBLE_API, MEU_ACERTO_LOGIN } from '@/common/constants/endpoints'

interface IInterHttp {
  url?: string
  method?: string
}

interface ResponseProps {
  eligible?: boolean
}

export const getURLRedirectMeuAcerto = async (): Promise<IInterHttp> => {
  let response: IInterHttp = {}
  try {
    const { theme } = await interWbSession.getAppInfo()
    response = await interWbHttp
      .get(`${MEU_ACERTO_LOGIN}`, {
        'x-inter-tema': theme || 'inter',
      })
      .then((res) => {
        const value: { webView: IInterHttp } = JSON.parse(res.response)
        return value.webView
      })
  } catch (err) {
    global.console.error('ERROR', err)
  }
  return response
}

export const getIsEligible = async (): Promise<ResponseProps> => {
  let response: ResponseProps = {}
  try {
    const { theme } = await interWbSession.getAppInfo()
    const userInfo = await interWbSession.getUserInfo()
    response = await interWbHttp
      .get(`${ELIGIBLE_API}`, {
        'x-inter-tema': theme || 'inter',
        'x-inter-user-bank-account': userInfo.account,
        'x-inter-user-social-id': userInfo.cpf,
      })
      .then((res) => {
        const eligible = JSON.parse(res.response)
        return eligible
      })
  } catch (err) {
    global.console.error('ERROR', err)
  }
  return response
}
