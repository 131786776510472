import React, { useState } from 'react'

import { interWbNavigate } from '@interco/inter-webview-bridge'
import { Text } from '@interco/inter-ui/components/Text'
import { Button } from '@interco/inter-ui/components/Button'
import { Checkbox } from '@interco/inter-ui/components/Checkbox'
import { TERMS_MEU_ACERTO, POLICY_INTER } from '@/common/constants/endpoints'

import * as S from './style'

const Terms: React.FC<{ setCanRedirect: (status: boolean) => void }> = ({ setCanRedirect }) => {
  const [terms, setTerms] = useState({ agree: false, read: false })

  const navigateTo = (url = '/') => {
    interWbNavigate.openNativeBrowser(url)
  }

  return (
    <S.WrapperFooter>
      <S.WrapperFooterInner>
        <S.ContainerCheckBox active={terms.read}>
          <Text variant="body-3" color="typography" colorWeight={400}>
            Li e concordo com os{' '}
            <S.Link onClick={() => navigateTo(TERMS_MEU_ACERTO)}>
              Termos e Política de Privacidade
            </S.Link>{' '}
            da Acerto.
          </Text>
          <Checkbox
            id="read"
            checked={terms.read}
            onChange={() => {
              setTerms((s) => ({ ...s, read: !s.read }))
            }}
          />
        </S.ContainerCheckBox>
        <S.ContainerCheckBox active={terms.agree}>
          <Text variant="body-3" color="typography" colorWeight={400}>
            Concordo com o tratamento dos meus dados e com a{' '}
            <S.Link
              onClick={() => {
                navigateTo(POLICY_INTER)
              }}
            >
              Política de Privacidade
            </S.Link>{' '}
            do Inter
          </Text>
          <Checkbox
            id="agree"
            checked={terms.agree}
            onChange={() => setTerms((s) => ({ ...s, agree: !s.agree }))}
          />
        </S.ContainerCheckBox>
        <Button disabled={!terms.agree || !terms.read} onClick={() => setCanRedirect(true)}>
          Consultar minhas dívidas
        </Button>
      </S.WrapperFooterInner>
    </S.WrapperFooter>
  )
}

export default Terms
