const ENABLE_LOG = process.env.REACT_APP_DEBUG === 'true' && process.env.NODE_ENV !== 'test'

export const log = (module: string, ...args: unknown[]): void => {
  if (ENABLE_LOG) {
    window.console.log(`=> ${module}`, ...args)
  }
}

type ErrorDetails = {
  screenOrModule: string
  reason: string
  userAccount: string
}

export const error = ({ screenOrModule }: ErrorDetails, ...args: unknown[]): void => {
  if (ENABLE_LOG) {
    window.console.error(`=> ${screenOrModule}`, ...args)
  }
}
