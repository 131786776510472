import styled, { css } from 'styled-components'

export const WrapperTerms = styled.div`
  display: grid;
  gap: 16px;
  padding: 32px 24px 24px;
`

export const Term = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px;
  gap: 17px;
`

export const Link = styled.span`
  width: auto;
  text-decoration: none;
  color: var(--primary500);
  font-weight: bold;
  outline: none;
  background-color: transparent;
`
export const WrapperFooter = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 24px 24px;
  background-color: var(--neutral-theme);
  button {
    width: 100%;
    margin-top: 12.5px;
    max-height: 48px !important;
    height: 48px;
  }
`
export const ContainerCheckBox = styled.div<{ active?: boolean }>`
  ${({ active = false }) => css`
    padding: 0 0 10px 0;
    text-align: justify;
    display: flex;
    height: 65px;
    path {
      --checkbox-color: ${active === false ? 'var(--gray300)' : 'var(--primary500)'};
    }
    align-items: center;
    justify-content: space-between;
    span {
      margin-right: 10px;
    }
  `}
`

export const WrapperFooterInner = styled.div``
