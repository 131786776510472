import React from 'react'

import { Skeleton } from '@interco/inter-ui/components/Skeleton'

import * as S from './styles'

export const HomeShimmer = () => (
  <>
    <S.Container>
      <S.TextContainerDonation>
        <Skeleton width="100%" height="256px" />
      </S.TextContainerDonation>
      <S.TextContainer>
        <Skeleton width="100%" height="60px" />
        <Skeleton width="100%" height="50px" style={{ marginTop: '16px' }} />
      </S.TextContainer>
      <S.ContainerSlick>
        <Skeleton width="100%" height="20px" />
        <Skeleton width="100%" height="152px" style={{ marginTop: '18px' }} />
      </S.ContainerSlick>
      <S.ContainerSlick>
        <Skeleton width="100%" height="20px" />
        <Skeleton width="100%" height="152px" style={{ marginTop: '18px' }} />
      </S.ContainerSlick>
      <S.ContainerSlick>
        <Skeleton width="100%" height="20px" />
        <Skeleton width="100%" height="152px" style={{ marginTop: '18px' }} />
      </S.ContainerSlick>
      <S.ContainerSlick>
        <Skeleton width="100%" height="20px" />
        <Skeleton width="100%" height="152px" style={{ marginTop: '18px' }} />
      </S.ContainerSlick>
    </S.Container>
  </>
)

export default HomeShimmer
