export enum DeepLinksPF {
  CARD = 'bancointer://cartoes',
  HOME = 'bancointer://login',
}

export enum DeepLinksPJ {
  CARD = 'bancointerpj://cartoes',
  HOME = 'bancointerpj://login',
}

export type DeepLinksKeys = keyof typeof DeepLinksPF | keyof typeof DeepLinksPJ

export const DeepLinks = {
  ...Object.keys(DeepLinksPF).reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue]: currentValue,
    }),
    {},
  ),
  ...Object.keys(DeepLinksPJ).reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue]: currentValue,
    }),
    {},
  ),
} as Record<DeepLinksKeys, DeepLinksKeys>
