import React from 'react'

import { ReactComponent as AppStoreIcon } from 'inter-frontend-svgs/lib/stores/app-store-color-pt.svg'
import { ReactComponent as GooglePlayIcon } from 'inter-frontend-svgs/lib/stores/play-store-color-pt.svg'

import { ReactComponent as Persona } from '@/assets/images/persona.svg'
import { Text } from '@interco/inter-ui/components/Text'

import * as S from './styles'

const BrowserEnvironmentView = () => (
  <S.Container data-testid="browser-env" role="main">
    <Persona />
    <Text variant="headline-h1" color="typography" colorWeight={400}>
      Baixe o App para acessar este recurso.
    </Text>
    <S.WrapperButtons>
      <a href="https://apps.apple.com/br/app/inter-conta-digital-finan%C3%A7as/id839711154">
        <AppStoreIcon />
      </a>
      <a href="https://play.google.com/store/apps/details?id=br.com.intermedium">
        <GooglePlayIcon />
      </a>
    </S.WrapperButtons>
  </S.Container>
)

export default BrowserEnvironmentView
