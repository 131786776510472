import React, { PropsWithChildren } from 'react'

import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { NotificationProvider } from '@interco/inter-ui/components/Notification'

import { GlobalStateProvider } from './global-state'
import { queryClient } from './query-client'
import StateInspector from './state-inspector'

const AppProviders = ({ children }: PropsWithChildren<unknown>) => (
  <GlobalStateProvider>
    <StateInspector>
      <QueryClientProvider client={queryClient}>
        <NotificationProvider>
          {children}
          <ReactQueryDevtools initialIsOpen />
        </NotificationProvider>
      </QueryClientProvider>
    </StateInspector>
  </GlobalStateProvider>
)

export default AppProviders
