import styled from 'styled-components'

export const Container = styled.header`
  position: sticky;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  width: 100%;
  background-color: var(--neutral-theme);
`
