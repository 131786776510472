import { IWbHttpOptions, WbSessionActions } from '@interco/inter-webview-bridge'

enum CustomActions {
  GET_SUPER_APP_TYPE = 'getSuperAppType',
  DOWNLOAD_TERM = 'downloadTerm',
  NAVIGATE_HOME = 'navigateHome',
  NAVIGATE_TO_HOME = 'navigateToHome',
  OPEN_NATIVE_BROWSER = 'openNativeBrowser',
}

export const Actions = {
  ...WbSessionActions,
  ...CustomActions,
}

type CommonArguments = {
  endpoint: string
  headers?: Record<string, string>
  options?: IWbHttpOptions
}

type GetOrDelete = CommonArguments & {
  method: 'GET' | 'DELETE'
}

export type PostOrPut = CommonArguments & {
  method: 'POST' | 'PUT'
  body?: Record<string, unknown>
}

export type Request = GetOrDelete | PostOrPut

export enum DeepLinks {
  NATURA = 'bancointer://intershop/afiliados/loja/natura',
  HOME = 'bancointer://login',
  ELEVE = 'bancointer://intershop/afiliados/loja/eleve-life',
  BODY = 'bancointer://intershop/afiliados/loja/the-body-shop',
  LIFE = 'bancointer://intershop/afiliados/loja/pura-vida',
  BOTICARIO = 'bancointer://intershop/afiliados/loja/o-boticario',
  EVER = 'bancointer://marketplace/lojas?id=evergreen&corTexto=%23ffffff&corFundo=%232a864c&nome=Ever%20Green',
  ORGANICO = 'bancointer://marketplace/lojas?id=useorganico&corTexto=%23FFFFFF&corFundo=%238EB935&nome=Use%20Org%C3%A2nico',
  EUDORA = 'bancointer://marketplace/lojas?id=eudora&corTexto=%23FFFFFF&corFundo=%23E1A875&nome=Eudora',
  TROCAFONE = 'bancointer://marketplace/lojas?id=trocafone&corTexto=%23ffffff&corFundo=%23f27024&nome=Trocafone',
  GSS_CARBON = 'bancointer://intershop/afiliados/loja/gsscarbon',
  PIX = 'bancointer://pix/chavePix?keyValue=doacao@gerandofalcoes.com',
  PIX_IOS = 'bancointer://pix/chave?pixKey=doacao@gerandofalcoes.com',
  PANGEIA = 'bancointer://intershop/afiliados/loja/pangeia',
  FAZENDINHA = 'bancointer://intershop/afiliados/loja/fazendinha',
  DOUTOR_INTER = 'bancointer://seguros/telemedicina',
  // DOUTOR_INTER = 'bancointer://meiosPagamento/rota/FRIENDLY_NEGOTIATION',
}
export type SuperAppType = {
  superAppType: AppType
}

export type AppInfo = {
  version?: string
  theme?: string
  tema?: string
  deviceName?: string
  deviceType?: string
  nomeAparelho?: string
  versao?: string
  nomeTipo?: string
}

export type UserInfo = {
  account: string
  addressComplement: string
  addressName: string
  addressNumber: string
  birthDate: string
  cellPhoneNumber: string
  cep: string
  city: string
  cpf: string
  email: string
  name: string
  neighborhood: string
  phoneNumber: string
  state: string
  segmentation?: string
}
