export const pageData = {
  title: 'Negocie suas dívidas e garanta descontos!',
  description:
    'Com a parceria entre o Inter e a Acerto, te ajudamos com algumas das suas dívidas e você ainda faz uma negociação na hora!',
  info: {
    title: 'É bem simples!',
    steps: [
      'Você consulta se possui dívidas com o Inter e com a nossa parceira Acerto;',
      'Você envia a sua proposta de negociação da dívida e tem a resposta na hora!',
    ],
  },
  questions: {
    title: 'Como funciona',
    steps: [
      {
        title: 'Se possui dívidas com cartão Inter',
        description:
          'Você conseguirá negociar as dívidas do seu cartão de crédito Inter com condições especiais.',
      },
      {
        title: 'Se possui dívidas com parceiros Acerto',
        description:
          'Estando tudo certo com seu cartão Inter, você verá se possui dívidas nas instituições parceiras.',
      },
    ],
  },
}
